import request from "../utils/requst"


export const SearchComplaintOrderUrl = params => {
    return request({
        method: 'GET',
        url: 'procedure/complaint-work-order/page',
        params,
    })
}

export const SearchOneComplaintOrderUrl = params => {
    return request({
        method: 'GET',
        url: 'procedure/complaint-work-order/get',
        params,
    })
}

export const getReportInfoUrl = params => {
    return request({
        method: 'GET',
        url: '/procedure/complaint-work-order/getReportInfo',
        params,
    })
}

export const editReportUrl = data => {
    return request({
        method: 'POST',
        url: '/procedure/complaint-work-order/editReport',
        data,
    })
}
export const confirmReporttUrl = data => {
    return request({
        method: 'POST',
        url: '/procedure/complaint-work-order/confirmReport',
        data,
    })
}

export const getreportPage = params => {
    return request({
        method: 'GET',
        url: '/procedure/complaint-work-order/reportPage',
        params,
    })
}

export const createQualityReport = data => {
    return request({
        method: 'POST',
        url: '/procedure/complaint-work-order/createQualityReport',
        data,
    })
}

export const confirmComplaintLevelUrl = data => {
    return request({
        method: 'POST',
        url: '/procedure/complaint-work-order/confirmComplaintLevel',
        data,
    })
}


